<template>
  <footer class="footer">
    <v-container class="pb-0">
      <div class="w-full grid gap-4 grid-cols-12 mb-4!">
        <div class="col-span-12 md:col-span-4">
          <h2>
            Join Orie Community and receive a <mark>10% discount</mark> <br />
            welcome voucher
          </h2>
          <p class="my-4">
            Create an account and organise your shopping lists, saved items,
            events, recipes, and more. It’s all free.
          </p>
          <v-btn color="black" variant="flat">
            Create an account or login
          </v-btn>
        </div>
        <div class="col-span-12 md:col-span-4">
          <h2>Create an Orie Business <br />account</h2>
          <p class="my-4">
            Bring your business to the creative marketplace where millions of
            shoppers are looking for products like yours.
          </p>
          <div class="mb-4">
            <a class="dotted-link" :href="brochureUrl" target="_blank">
              Learn more
            </a>
          </div>
          <v-btn
            color="black"
            variant="flat"
            :href="dashboardUrl"
            target="_blank"
          >
            Seller Dashboard
            <v-icon icon="arrow_forward" size="x-small" class="ml-1" />
          </v-btn>
        </div>
        <div class="col-span-12 md:col-span-2 mt-4 md:mt-0">
          <h3 class="text-3.5">Help and support</h3>
          <div class="mt-2">
            <div
              v-for="link in supportLinks"
              :key="link.text"
              class="text-3.6 line-height-5"
            >
              <router-link :to="link.to">
                {{ link.text }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-span-12 md:col-span-2 mt-4 md:mt-0">
          <h3 class="text-3.5">About</h3>
          <div class="mt-2">
            <div
              v-for="link in companyLinks"
              :key="link.text"
              class="text-3.6 line-height-5"
            >
              <router-link :to="link.to">
                {{ link.text }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <v-divider class="mb-2!" />

      <div class="sublinks">
        <v-row align-content="center" class="ma-0 px-0">
          <v-col cols="12" md="auto" class="pa-0">
            <v-row class="h-full" align-content="center" no-gutters>
              <span>
                © {{ new Date().getFullYear() }} Orie. All rights reserved.
              </span>
            </v-row>
          </v-col>

          <v-col cols="12" md="auto" class="pa-0 ml-md-10">
            <v-row class="h-full" align-content="center" no-gutters>
              <template v-for="(link, i) in legalLinks" :key="link.text">
                <span class="mx-2" v-if="i">·</span>
                <router-link class="dotted-link" :to="link.to">
                  {{ link.text }}
                </router-link>
              </template>
            </v-row>
          </v-col>

          <v-spacer class="md:hidden" />

          <v-col cols="12" md="auto" class="md:hidden">
            <span class="mr-5">
              Handcrafted with
              <span class="text-red">
                <v-icon size="15" icon="favorite" />
              </span>
            </span>
            <theme-switch class="mr-3" size="x-small" variant="text" />
            <span class="font-weight-bold">
              {{ currency.symbol }} {{ currency.code }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemeSwitch from "../../elements/ThemeSwitch.vue";

export default defineComponent({
  components: { ThemeSwitch },
  setup() {
    const { currency } = useCurrency();
    const config = useRuntimeConfig();

    return {
      currency,
      brochureUrl: config.public.links.brochure,
      dashboardUrl: config.public.links.dashboard,
    };
  },
  data: () => ({
    supportLinks: [
      // {
      //   text: "Help centre",
      //   to: { name: "help" },
      // },
      {
        text: "FAQs",
        to: { name: "help-faqs" },
      },
      {
        text: "Contact us",
        to: { name: "contact-us" },
      },
      {
        text: "Leave a feedback",
        to: { name: "contact-us" },
      },
    ],
    legalLinks: [
      {
        text: "Terms of Use",
        to: "/legal/terms",
      },
      {
        text: "Privacy Policy",
        to: "/legal/privacy",
      },
      {
        text: "Sales",
        to: "/legal/sales",
      },
      // {
      //   text: "Sales and Refunds",
      //   to: "/legal/sales",
      // },
      {
        text: "Legal",
        to: "/legal",
      },
      // { text: "Blog", to: { name: "blog" } },
    ],
  }),
  computed: {
    companyLinks() {
      return [
        {
          text: "For business",
          to: `//${this.brochureUrl}`,
        },
        {
          text: "About us",
          to: { name: "about" },
        },
        {
          text: "Policies",
          to: "/legal",
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.footer {
  padding-top: 4rem;
  padding-bottom: 1rem;
  background-color: rgba(230, 195, 68, 0.223);
  display: block;
  width: 100vw;
  mark {
    display: inline-block;
    line-height: 0em;
    padding-bottom: 0.5em;
  }
  .sublinks {
    width: 100%;
    font-size: 0.7rem;
    @include breakpoints-up(md) {
      font-size: 0.8rem;
    }
  }
  @include breakpoints-down(md) {
    margin-bottom: 50px;
  }
}
</style>
