<template>
  <v-container
    tag="header"
    class="w-full flex justify-between !md:py-3 !px-0"
    :class="{
      '!py-4': disableStick,
      '!py-8': !disableStick,
    }"
    v-bind="$attrs"
  >
    <div class="w-auto hidden md:flex items-center relative">
      <router-link to="/" class="no-underline">
        <div class="h-full flex items-center">
          <themed-logo size="30" />
          <span class="ml-2 font-semibold">Orie </span>
        </div>
      </router-link>
    </div>
    <div
      class="pointer-events-none top-2 left-2 right-2 flex justify-center"
      :class="{
        fixed: !disableStick,
        'z-[2401]': !disableStick || showMenu,
        'w-full': disableStick,
      }"
    >
      <div class="w-full md:w-auto md:bg-transparent md:shadow-none px-1">
        <v-sheet
          class="card-rest p-1 md:p-1.5 transition-all pointer-events-auto"
          :class="{ stock: stock && !disableStick, searching }"
        >
          <div class="relative flex items-center justify-between">
            <div class="ml-2 md:hidden flex items-center">
              <router-link to="/">
                <themed-logo class="mt-1" size="30" />
              </router-link>
            </div>
            <div class="flex items-center gap-2">
              <nav class="h-full hidden md:block">
                <ul
                  class="h-full list-none flex flex-col md:flex-row justify-center md:justify-start gap-6 md:gap-0 lg:gap-1"
                >
                  <li v-for="(link, i) in desktopLinks" :key="i">
                    <v-btn :to="link.to" variant="flat">
                      <span class="normal-case">{{ link.title }}</span>
                    </v-btn>
                  </li>
                </ul>
              </nav>
              <div
                class="hidden md:inline-block stock-items-extra overflow-hidden"
              >
                <div class="stock-items-extra__container flex items-center">
                  <!-- extra menu -->
                  <v-btn @click="showSell" color="black" class="px-2">
                    <span class="normal-case">For business</span>
                  </v-btn>
                </div>
              </div>
              <div class="hidden md:inline-block">
                <v-btn
                  @click.prevent="showSearch"
                  class="ml-2"
                  icon="search"
                  size="small"
                  variant="flat"
                />
                <!-- <v-btn
                  @click.prevent="showSearch"
                  class="ml-2 group flex items-center rounded-xl py-1 pe-1 ps-3"
                  variant="flat"
                  aria-label="Open search"
                  fdprocessedid="sta3ed"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="icon h-4 w-4 motion-safe:transition-colors motion-safe:duration-300"
                    style=""
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    data-v-cd102a71=""
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <path d="m21 21l-4.35-4.35"></path>
                    </g>
                  </svg>
                  <span
                    class="rounded-lg border px-1 ml-1 py-0.5 motion-safe:transition-colors motion-safe:duration-300"
                    ><kbd class="font-sans text-3 tracking-wide">ctrl + k </kbd>
                  </span>
                </v-btn> -->
              </div>
              <div class="flex md:hidden items-center">
                <v-btn @click="showSell" color="black" class="px-2">
                  <span class="normal-case">For business</span>
                </v-btn>
                <v-btn
                  @click="toggleMenu"
                  class="ml-2"
                  size="small"
                  :icon="showMenu ? 'close' : 'menu'"
                />
              </div>
            </div>
          </div>
        </v-sheet>
        <div class="hidden">
          <desktop-search />
        </div>
      </div>
    </div>
    <!-- <div
      class="pointer-events-none bg-theme-bg-base fixed inset-0 z-50 lg:hidden"
      style="opacity: 0; transform: none"
    >
    </div> -->
    <div class="hidden md:block relative">
      <div class="flex gap-3 items-center">
        <v-btn @click="showSell" color="black" class="px-2">
          <span class="normal-case">For business</span>
        </v-btn>
        <v-divider vertical class="my-2" />
        <cart-btn size="small" variant="flat" />
      </div>
    </div>
  </v-container>
  <action-dialog v-model="sellDialog">
    <div class="px-10 pb-10">
      <v-row class="py-10" justify="center" align-content="center" no-gutters>
        <logo />
      </v-row>
      <div class="text-center mb-5">
        Sell Products, Services, <br />Event Tickets, on Orie
      </div>
      <v-btn :href="dashboardUrl" color="black" target="_blank" block
        >Get started</v-btn
      >
      <v-btn
        :href="brochureUrl"
        color="primary"
        target="_blank"
        class="mt-1"
        block
        >Learn more</v-btn
      >
    </div>
  </action-dialog>
  <v-dialog class="md:hidden" v-model="showMenu" fullscreen>
    <v-sheet class="pt-20">
      <v-list>
        <v-list-item
          v-for="item in mobileLinks1"
          :key="item.title"
          :title="item.title"
          :subtitle="item.subtitle"
          :to="item.to"
        >
          <template #prepend>
            <v-icon :icon="item.icon" />
          </template>
        </v-list-item>
        <v-divider class="ma-2" />
        <v-list-item
          v-for="item in mobileLinks2"
          @click="hideMenu"
          :key="item.title"
          :title="item.title"
          :subtitle="item.subtitle"
          :to="item.to"
        >
          <template #prepend>
            <v-icon :icon="item.icon" />
          </template>
        </v-list-item>
        <v-divider class="ma-2" />
        <v-list-item
          v-for="item in mobileLinks3"
          @click="hideMenu"
          :key="item.title"
          :title="item.title"
          :to="item.to"
          density="compact"
        />
      </v-list>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemedLogo from "~~/components/elements/ThemedLogo.vue";
import ActionDialog from "~~/components/dialogs/ActionDialog.vue";
import DesktopSearch from "~~/components/elements/search/DesktopSearch.vue";
import CartBtn from "../modules/CartBtn.vue";
import { useWindowScroll } from "@vueuse/core";
import { get } from "lodash";
import { useDesktopSearchStore } from "~~/stores/desktop-search";
import { storeToRefs } from "pinia";

export default defineComponent({
  components: { ThemedLogo, ActionDialog, CartBtn, DesktopSearch },
  setup() {
    const sellDialog = ref(false);
    const showMenu = ref(false);
    const config = useRuntimeConfig();
    const route = useRoute();

    const store = useDesktopSearchStore();
    const { visible: searching } = storeToRefs(store);

    const disableStick = computed(() =>
      get(route.meta, "disableHeaderStick", false)
    );

    const { y } = useWindowScroll();
    const stock = computed(() => y.value > 5);

    const showSell = () => {
      sellDialog.value = true;
    };

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const hideMenu = () => {
      showMenu.value = false;
    };

    const showSearch = () => {
      searching.value = true;
    };

    onBeforeRouteUpdate(hideMenu);

    return {
      stock,
      showSell,
      toggleMenu,
      sellDialog,
      showMenu,
      brochureUrl: config.public.links.brochure,
      dashboardUrl: config.public.links.dashboard,
      disableStick,
      showSearch,
      searching,
      hideMenu,
    };
  },
  data: () => ({
    desktopLinks: [
      { title: "Products", to: { name: "products" } },
      { title: "Services", to: { name: "services" } },
      { title: "Events", to: { name: "events" } },
      // { title: "Recipes" },
      // { title: "Returns", to: "/legal/returns" },
      { title: "About Us", to: { name: "about" } },
      //
    ],
    mobileLinks1: [
      {
        title: "Products",
        subtitle: "Browse listed products",
        icon: "storefront",
        to: { name: "products" },
      },
      {
        title: "Services",
        subtitle: "Browse services",
        icon: "design_services",
        to: { name: "services" },
      },
      {
        title: "Events",
        subtitle: "Browse local events",
        icon: "event",
        to: { name: "events" },
      },
      // {
      //   title: "Recipes",
      //   subtitle: "Browse African recipes",
      //   icon: "restaurant",
      //   // to: { name: "recipes" },
      // },
      // {
      //   title: "Saved items",
      //   subtitle: "Saved products, events, recipes, etc.",
      //   icon: "bookmark_border",
      //   // to: { name: "profile-lists" },
      // },
      {
        title: "Ask a question",
        subtitle: "Ask us anything. Find out more about communities around you",
        icon: "help_outline",
        // to: { name: "help-ask" },
      },
      // {
      //   title: "Businesses",
      //   subtitle: "Find local businesses",
      //   icon: "work_outline",
      //   // to: { name: "businesses" },
      // },
      // {
      //   title: "Blog",
      //   subtitle: "Orie updates, announcements and press",
      //   icon: "campaign",
      //   // to: { name: "blog" },
      // },
    ],
    mobileLinks2: [
      // {
      //   title: "Help centre",
      //   subtitle: "Get help from our support team",
      //   icon: "support",
      //   // to: { name: "help" },
      // },
      // {
      //   title: "Returns",
      //   subtitle: "Get help from our support team",
      //   icon: "swap_horiz",
      //   // to: { name: "help-returns" },
      // },
      {
        title: "Feedback",
        subtitle: "Leave us a feedback",
        icon: "contact_support",
        to: { name: "contact-us" },
      },
    ],
  }),
  computed: {
    mobileLinks3() {
      return [
        { title: "For business", to: `//${this.brochureUrl}` },
        {
          title: "Who we are",
          to: { name: "about" },
        },
        // {
        //   title: "Terms of use",
        //   to: "/legal/terms",
        // },
        // {
        //   title: "Privacy policy",
        //   to: "/legal/privacy",
        // },
        {
          title: "Legal",
          to: { name: "legal" },
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
@mixin card {
  box-shadow: 0 0 0 1px rgba(17, 24, 28, 0.08),
    0 1px 2px -1px rgba(17, 24, 28, 0.08), 0 2px 4px rgba(17, 24, 28, 0.04) !important;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.searching {
  opacity: 0;
  pointer-events: none;
  // display: none;
  visibility: hidden;
}

.card-rest {
  @apply rounded-full;
  @screen lt-sm {
    @include card;
  }

  .stock-items-extra {
    width: 0px;
  }
  &.stock {
    @include card;
    .stock-items-extra {
      width: auto;
      &__container {
        animation: slideIn 0.25s ease-in-out;
      }
    }
  }
}
</style>
